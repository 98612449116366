import React, { Suspense } from 'react'

import { Main } from './Main'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorView } from './components/error/ErrorView'
import { ErrorBoundary } from 'react-error-boundary'

function App() {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <Suspense fallback={<SpinnerFullHeight />}>
          <Main />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

export default App
