/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/08/2023.
 */
import React from 'react'
import { DEFAULT_BGIMAGE, S3_BUCKET_URL } from '../constants'
import { ConfigType } from '../Main'
import { Button } from '../components/Button'

type Props = {
  data: ConfigType
  navigate: (value: number) => void
  gotoPage: number
}

export const PrizePage = ({ data, navigate, gotoPage }: Props) => {
  const prizeImg = data?.prizeImage ? S3_BUCKET_URL + '/' + data.prizeImage : DEFAULT_BGIMAGE

  const prize = data?.endPage ? data.endPage : { headline: '', body: '', prizeTxt: '', btn: '', link: '' }

  return (
    <>
      {/*<Header data={data}></Header>*/}
      <div className="row ">
        <div className="col-12 col-md-8 mx-auto mt-4">
          <h2 className="text-center">{prize.headline}</h2>
          <p className="text-center mt-4">{prize.body}</p>
        </div>

        <div className="col-12 ratio ratio-16x9 my-3">
          <div
            className="h-100 w-100"
            style={{
              backgroundImage: `url(${prizeImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          ></div>
        </div>

        {prize.prizeTxt ? (
          <div className="col-12  col-md-8 mx-auto mb-4">
            <p className="text-center px-2 ">{prize.prizeTxt}</p>
          </div>
        ) : null}

        {prize.btn ? (
          <div className="col-12 d-flex justify-content-center mb-5  ">
            <a href={prize.link}>
              <Button label={prize.btn}></Button>
            </a>
          </div>
        ) : null}
      </div>
    </>
  )
}
