/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { Layout } from './components/display/Layout'
import { useConfig } from './hooks/useConfig'
import { WelcomePage } from './pages/WelcomePage'
import { TabViewNavigator } from './components/navigator/TabViewNavigator'
import React, { useEffect, useState } from 'react'
import { RegistrationPage } from './pages/RegistrationPage'
import { SpinPage } from './pages/SpinPage'
import { PrizePage } from './pages/PrizePage'
import { Header } from './components/Header'
import { DEBUG, PROJECT_REF } from './constants'
import facebookImg from './assets/facebook.png'
import instaImg from './assets/insta.png'
import youtubeImg from './assets/youtube.png'
import homeImg from './assets/home.png'
import { configStore } from './store/configStore'

export type ConfigType = {
  _id: string
  wheel: {
    ringColor: string
    primaryColor: string
    secondaryColor: string
  }
  page: {
    prize: string
    prizeDescription: string
    headline: string
    body: string
    startBtn: string
    spinBtn: string
    seePrizeBtn: string
  }
  spinPage: {
    winHeadline: string
    winBody: string
    spinBtn: string
    winBtn: string
  }
  links: {
    facebook: string
    youtube: string
    instagram: string
    website: string
  }
  registration: {
    headline: string
    description: string
    submitBtn: string

    terms: string
    termsHeadline: string

    formFirstName: string
    formLastName: string
    formEmail: string
    formPhone: string

    errorFormPhone: string
    errorFormFirstName: string
    errorFormLastName: string
    errorFormEmail: string
    errorDropDown: string
    errorCheckbox: string
    errorCheckboxTwo: string

    dropDownTitle: string
    dropDownOptionOne: string
    dropDownOptionTwo: string
    dropDownOptionThree: string
    dropDownOptionFour: string
    dropDownOptionFive: string
    dropDownOptionSix: string

    checkBoxOne: string
    checkBoxTwo: string
  }

  endPage: {
    headline: string
    body: string
    prizeTxt: string
    btn: string
    link: string
  }

  footer: {
    headline: string
    body: string
    footerBtn: string
    link: string
  }

  active: boolean
  title: string
  client: string
  url: string
  start: string
  end: string
  market: string
  prizeImage: string
  logo: string
  bgImage: string
  createdAt: Date
  updatedAt: Date
}

export const Main = () => {
  const projectRef: string = DEBUG ? '65cfa93546e9053703abdaac' : (PROJECT_REF as string)
  const { data } = useConfig(projectRef as string)

  const setconfig = configStore((state) => state.setConfig)

  useEffect(() => {
    setconfig(data)
  }, [data, setconfig])

  const [pageIndex, setPageIndex] = useState<number>(0)

  const navigate = (value: number) => {
    setPageIndex(value)
  }

  return (
    <>
      <Layout data={data}>
        {/*{DEBUG ? (
          <Wrapper data={data}>
            <RegistrationPage data={data} gotoPage={3} navigate={navigate}></RegistrationPage>
          </Wrapper>
        ) : null}*/}
        <TabViewNavigator pageIndex={pageIndex} direction={200}>
          <Wrapper data={data}>
            <WelcomePage data={data} gotoPage={1} navigate={navigate}></WelcomePage>
          </Wrapper>
          <Wrapper data={data}>
            <SpinPage data={data} gotoPage={2} navigate={navigate}></SpinPage>
          </Wrapper>
          <Wrapper data={data}>
            <RegistrationPage data={data} gotoPage={3} navigate={navigate}></RegistrationPage>
          </Wrapper>
          <Wrapper data={data}>
            <PrizePage data={data} gotoPage={3} navigate={navigate}></PrizePage>
          </Wrapper>
        </TabViewNavigator>
      </Layout>
    </>
  )
}

const Wrapper = ({ children, data }) => {
  return (
    <>
      <div className="text-white h-100 d-flex flex-column " style={{}}>
        <Header data={data}></Header>
        <div className="row h-100">
          <div className="col-12 col-md-6 col-md-10 mx-auto  " style={{ maxWidth: 800 }}>
            {children}
          </div>
          <Footer data={data}></Footer>
        </div>
      </div>
    </>
  )
}

type FooterProps = {
  data: ConfigType
}
export const Footer = ({ data }: FooterProps) => {
  const facebook = data?.links?.facebook || ''
  const instagram = data?.links?.instagram || ''
  const youtube = data?.links?.youtube || ''
  const website = data?.links?.website || ''

  const footer = data?.footer || { headline: '', body: '', footerBtn: '', link: '' }

  const arr = [
    { label: 'facebook', url: facebook, img: facebookImg },
    { label: 'instagram', url: instagram, img: instaImg },
    { label: 'youtube', url: youtube, img: youtubeImg },
    { label: 'website', url: website, img: homeImg },
  ].filter((item) => item.url)

  return (
    <div
      className="col-12 pb-7  mx-auto  "
      style={{ backgroundColor: 'rgba(0,0,0,.5)', borderTop: '1px solid rgba(255,255,255,.3)' }}
    >
      <div className="row" style={{}}>
        <div className="col-12 col-md-4 mx-auto">
          <h5 className="text-center my-4 text-white">{footer.headline}</h5>
          <p className="p-small text-center text-white">{footer.body}</p>

          {footer.footerBtn ? (
            <div className="d-flex justify-content-center align-items-center mt-4 mt-md-0">
              <a href={footer.link} className="text-decoration-none">
                <button
                  className="btn btn-primary rounded-pill py-2 py-md-2 px-7 px-md-7 mt-md-4 fw-bold"
                  style={{ border: 'none', background: '#282d46' }}
                >
                  <p className="p-small px-2 py-1">{footer.footerBtn}</p>
                </button>
              </a>
            </div>
          ) : null}

          <div className="d-flex justify-content-evenly px-2 mt-4 mt-md-5">
            {arr.map((item, index) => {
              const { url, img } = item
              return (
                <a key={index} href={url} className="mx-2">
                  <img src={img} className="img-fluid" style={{ maxHeight: 60 }} alt="" />
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
