/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/05/2023.
 */
import React, { useEffect, useRef } from 'react'
import { animated, useTransition } from '@react-spring/web'

type TabViewNavigatorProps = {
  pageIndex: number
  direction: number
  children: React.ReactElement | React.ReactElement[]
}
export const TabViewNavigator = ({ pageIndex, direction, children }: TabViewNavigatorProps) => {
  const pageArr = React.Children.toArray(children)

  const containerRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const transitions = useTransition(pageIndex, {
    from: { transform: `translate3d(${direction}px,0,0)`, opacity: 0 },
    enter: { transform: 'translate3d(0px,0,0)', opacity: 1 },
    leave: { transform: `translate3d(${-direction}px,0,0)`, opacity: 0 },
  })

  //  const k = ref.current ? ref.current.getBoundingClientRect().height : 100
  // const aniProps = useSpring({ height: k, config: { tension: 400, friction: 40 } })

  useEffect(() => {
    console.log('TabViewNavigator > goto top = ', containerRef.current)
    console.log('TabViewNavigator > ref = ', ref.current)

    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }

    document.body.scrollTop = 0
  }, [pageIndex])

  return (
    /*<div className="row d-flex flex-column ">*/
    <animated.div
      ref={containerRef}
      className="position-relative row h-100"
      style={{ left: 0, top: 0, minHeight: '100vh' }}
    >
      {transitions((styles, item) => {
        return (
          <animated.div ref={ref} className="col-12 position-absolute h-100 " style={{ ...styles }}>
            {pageArr[item]}
          </animated.div>
        )
      })}
    </animated.div>
    /*</div>*/
  )
}
