/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import React from 'react'
import { ConfigType } from '../../Main'
import { DEFAULT_BGIMAGE, S3_BUCKET_URL } from '../../constants'

type Props = { data: ConfigType; children: React.ReactNode }

const bgImageStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}
export const Layout = ({ data, children }: Props) => {
  const bgImage = data?.bgImage ? S3_BUCKET_URL + '/' + data.bgImage : DEFAULT_BGIMAGE
  console.log('Layout > bgImage = ', bgImage)

  const bgImageUrl = `url(${bgImage})`

  return (
    <div className="container-fluid   " style={{ backgroundImage: bgImageUrl, ...bgImageStyle, overflowX: 'hidden' }}>
      {children}
    </div>
  )
}
