//import { authStore, getRefreshToken, getToken } from '../store/auth/authStore'
import { DEBUG } from '../constants'

export const getReq = async (p: any): Promise<any> => {
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken()

  return fetch(p, {
    method: 'GET',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      const contentType = res.headers.get('Content-Type')
      const isJson = contentType && contentType.indexOf('application/json') > -1

      if (DEBUG) {
        console.log(' API > GET:', res.status, p)
      }

      if (res.status === 404) {
        console.log(' API > res = ', res)
        const url = res.url || ''
        return Promise.reject(getErrorResponse(res, 404, 'Could not find endpoint ' + url))
      }

      if (res.status === 401) {
        console.log(' API GET > -- 401 401 401 401401 = ')
        console.log(' API > res = ', res)
        console.log('API > isJson = ', isJson)
        return Promise.reject(getErrorResponse(res, 401, 'NO AUTHHHH '))
      }

      if (!isJson) {
        return res.text()
      }

      return res.json()
    })
    .then((res) => {
      if (res.error || res.result === false) {
        console.log(' API > ALLLSEEEE = ')
        return Promise.reject(res)
      }

      return res
    })

    .catch((err) => {
      console.log(' API > err = ', err)
      console.log(' API > iseeeee = ')
      return Promise.reject(err)
    })
}

export const post = async (p: string, data: any, method: string = 'POST') => {
  //const token = getToken()

  return fetch(p, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }

      if (res.status === 401) {
        console.log(' API POST > + 401 401 401 401401 = ')
        //
        // The error is parsed in the error handler
        //
        return Promise.reject(res)
      }

      return res.json()
    })
    .then((res) => {
      if (res.type === 'VALIDATION') {
        console.log(' API > res.message = ', res.message)
      }

      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })

    .catch((err) => {
      //
      // Sometimes the error has already been parsed to json
      // The error object should always have a result property
      //
      if (err.result !== false) {
        //
        // if the err is a raw request, then detect the contentr-type
        // and parse it to json
        //
        const contentType = err.headers.get('Content-Type')
        const isJsonErr = contentType && contentType.indexOf('application/json') > -1

        if (isJsonErr) {
          return err
            .json()
            .then((resultError) => {
              // rejects it and then and then its caught in the error handler
              return Promise.reject(resultError)
            })
            .catch((error) => {
              return Promise.reject(error)
            })
        }
      }

      return Promise.reject(err)
    })
}

export const put = async (p: string, data: any) => {
  return post(p, data, 'PUT')
}

function getErrorResponse(err, status: number, msg) {
  return {
    result: false,
    status: status,
    err: err,
    message: msg,
  }
}
