import { create } from 'zustand'

export interface MeType {
  config: any
  setConfig: (newConfig: any) => void
}

export const configStore = create<MeType>((set: any, get: any) => {
  return {
    config: null,
    setConfig: (newConfig) => {
      set({ config: newConfig })
    },
  }
})
