import { BASE_URL } from '../constants'

export const ENDPOINTS = {
  CONFIG: {
    GET: (domain) => {
      return BASE_URL + '/campaign/' + domain
    },
  },
  USER: {
    POST: BASE_URL + '/campaign',
  },
} as const
